var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Dialog",
    {
      ref: "dialog",
      attrs: { title: _vm.$t("title.agency.csvFileSelect"), color: "next" },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c(
                "v-btn",
                {
                  staticClass: "back",
                  attrs: { width: "33%", dark: "" },
                  on: {
                    click: function($event) {
                      return _vm.close()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("button.cancel")))]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "next",
                  attrs: {
                    width: "33%",
                    dark: _vm.isImportButtonEnabled,
                    disabled: !_vm.isImportButtonEnabled
                  },
                  on: {
                    click: function($event) {
                      return _vm.onClickImportButton()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("button.import")))]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("v-container", [
        _c("input", {
          ref: "file",
          staticClass: "px-4",
          staticStyle: { width: "100%" },
          attrs: { type: "file", accept: ".csv" },
          on: { change: _vm.loadCsvFile }
        })
      ]),
      _c("ErrorDialog", { ref: "errorDialog" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }